import { Route, Routes } from "react-router";
import Ambassadors from "./pages/ambassadors";
import Categories from "./pages/categories";
import Category from "./pages/categories/[id]";
import Contact from "./pages/contact";
import Event from "./pages/events/[id]";
import Events from "./pages/events/index";
import Gallery from "./pages/gallery";
import Home from "./pages/index";
import Post from "./pages/posts/id";
import Contribute from "./pages/contribute";

export default function App() {
  return (
    <>
      <Routes>
        <Route index path="/" element={<Home />} />
        <Route path="/events" element={<Events />} />
        <Route path="/events/:id" element={<Event></Event>} />
        <Route path="/categories" element={<Categories />} />
        <Route path="/categories/:id/:title" element={<Category />} />
        <Route path="/aboutus" element={<Ambassadors />} />
        <Route path="/resources" element={<Gallery />} />
        <Route path="/resources/:index" element={<Gallery />} />
        <Route path="/post/:id" element={<Post />} />
        <Route path="/contactus" element={<Contact />} />  
        <Route path="/contribute" element={<Contribute />} />  
      </Routes>
    </>
  );
}
