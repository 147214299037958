import Footer from "../components/footer/footer";
import Logo from "../components/logo";
import Navbar from "../components/navbar/navbar";


const ErrorPage= ()=>{
    return <>


    <div className="  min-h-screen flex flex-col justify-center items-center">
        <Logo dark={true}  mini={false} height={200} width={200}></Logo>
        <h1 className="text-3xl">An Error Occured Loading Data...</h1>
      </div>

    
    </>

}


export default ErrorPage;