import { FunctionComponent } from "react";
import AmbassadorSection from "../components/ambassadors/ambassadorsection";

import OurVision from "../components/ambassadors/our-vision";
import Layout from "./layout";
import Purpose from "../components/ambassadors/purpose";

const Ambassadors: FunctionComponent = () => {
  return (
    <>
      <Layout>
        <OurVision />
        <Purpose/>
        <AmbassadorSection />
      </Layout>
    </>
  );
};

export default Ambassadors;
