import { doc } from "firebase/firestore";
import { FunctionComponent } from "react";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import { useInView } from "react-intersection-observer";
import { useParams } from "react-router";
import ErrorPage from "../../components/error";
import Footer from "../../components/footer/footer";
import Loading from "../../components/loading";
import Navbar from "../../components/navbar/navbar";
import { postConverter } from "../../interfaces/post";
import para from "../../resources/FirstSection/parade.jpg";
import { db } from "../../services/firebaseconfig";

const Post: FunctionComponent = () => {
  const { ref, inView } = useInView({ threshold: 0 });

  const { id } = useParams();
  const [value, loading, error] = useDocumentDataOnce(
    doc(db, `posts/${id}`).withConverter(postConverter),
    {
      getOptions: { source: "server" },
    }
  );
  return (
    <>
      <Navbar />
      {error ? (
        <ErrorPage></ErrorPage>
      ) : loading ? (
        <Loading></Loading>
      ) : (
        <main className=" mt-16">
          <section>
            <div
              ref={ref}
              className=" h-[50vh]  bg-black relative overflow-hidden"
            >
              <img
                className=" absolute   opacity-40 w-full  h-full object-cover"
                alt="Parade"
                src={value?.image ?? para}
                style={{ objectFit: "cover" }}
              ></img>

              <div
                className={` absolute text-white flex flex-col justify-center items-center h-full  w-full ${
                  inView
                    ? "opacity-100 transition-all duration-1000 translate-y-0 "
                    : "opacity-0 transition-all duration-2000 translate-y-1/4"
                } `}
              >
                <div className=" w-fit">
                  <h1 className="  text-5xl font-bold">
                    {value?.title ?? "Title"}
                  </h1>
                  <div className=" h-1 rounded-full bg-white mt-5"></div>
                </div>
                <div className=" flex flex-col justify-center">
                  <div className=" flex justify-center items-center text-2xl font-semibold mt-8 ">
                    <p className=" px-4">{value?.title ?? "Title"}</p>
                  </div>
                  <div className="flex flex-row mt-4">
                    {value?.tags?.map((data) => (
                      <div
                        key={data}
                        className="bg-transparent text-white border-white  border-2 rounded-md text-center text-sm font-medium w-min px-4 py-1 mx-2"
                      >
                        {" "}
                        {data}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="  min-h-[50vh] p-4 md:px-40 Md:py-10 w-screen overflow-x-clip">
              <p>{value?.description}</p>
              <div className=" mt-8 h-1 w-4/6  bg-black/10 rounded"> </div>

              <p className=" font-medium text-sm mt-3"> Written By :</p>
            </div>
          </section>
        </main>
      )}
      <Footer></Footer>
    </>
  );
};

export default Post;
