import { FunctionComponent } from "react";
import "pure-react-carousel/dist/react-carousel.es.css";
import AliceCarousel from "react-alice-carousel";
import { useInView } from "react-intersection-observer";
import { infoConverter, infoInterface } from "../../interfaces/general_info";
import { useCollectionDataOnce } from "react-firebase-hooks/firestore";
import { collection, query } from "firebase/firestore";
import { db } from "../../services/firebaseconfig";

const Hero: FunctionComponent = () => {
  const { ref, inView } = useInView({ threshold: 0, triggerOnce: true });

  const [content, contentLoading, contentError] = useCollectionDataOnce(
    query(
      collection(db, "content")
    ).withConverter(infoConverter),
    {
      getOptions: { source: "server" },
    }
  );
  const responsive = {
    0: { items: 1 },
  };
  const CarouselCard = (props: infoInterface) => {
    return (

        <div className=" h-[400px] md:h-[500px] w-full  overflow-hidden flex  justify-start ">
          
             <img
              className=" relative h-full w-full md:opacity-100  object-cover"
              alt={props.title}
              src={props.image}
            ></img> 
            
    
          <div className=" w-full h-full  bg-black opacity-50  absolute ">  
         
          </div>
          <div className=" w-full h-full flex justify-center items-center bg-transparent absolute ">  
          <h6 className="text-3xl font-medium">{props.title}</h6>
          </div>
        
        </div>

    );
  };
  const items =content?.map((data)=>(<CarouselCard image={data.image} title={data.title} description={data.description}></CarouselCard>));

  const Carousel = () => (
    <AliceCarousel
      mouseTracking
      autoPlay={true}
      disableButtonsControls={true}
      disableDotsControls={true}
      infinite={true}
      items={items}
      autoPlayInterval={4000}
      responsive={responsive}
      controlsStrategy="alternate"
    />
  );




  return (
    <section
      ref={ref}
      className="h-[400px] md:h-[500px]  w-screen  md:aspect-video text-white  overflow-clip flex justify-center items-center "
    >
      <Carousel></Carousel>
      {/* <div className=" w-full h-full z-0 block  absolute">
        <img
          className=" absolute  w-full h-full object-cover"
          alt="Parade"
          src={para}
        />
        <div className=" w-full h-full z-0 block bg-slate-950 opacity-60 absolute "></div>
      </div>
      <div
        className={`flex flex-col md:flex-row  justify-center md:items-center items-center w-full h-full absolute ${
          inView
            ? "opacity-100 transition-all duration-1000 translate-y-0 "
            : "opacity-0 transition-all duration-2000 translate-y-1/4"
        } `}
      >
        <div className=" md:h-full w-full  md:w-1/2 flex flex-col justify-center items-center p-4 text-3xl text-center font-semibold">
          <h1>The Intangible Cultural Heritage</h1>
          <div className=" h-1 my-4 rounded bg-white  w-9/12 "></div>
        </div>
      </div> */}
    </section>
  );
};

export default Hero;
