import { FunctionComponent, useState } from "react";

import { collection, query, where } from "firebase/firestore";
import { useCollectionDataOnce } from "react-firebase-hooks/firestore";
import { useParams } from "react-router";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import ErrorPage from "../components/error";
import Loading from "../components/loading";
import { GalleryInterface, galleryConverter } from "../interfaces/gallery";
import { db } from "../services/firebaseconfig";
import Layout from "./layout";

const Gallery = () => {
  const [images, imagesLoading, imagesError] = useCollectionDataOnce(
    query(
      collection(db, "gallery"),
      where("type", "==", "image")
    ).withConverter(galleryConverter),
    {
      getOptions: { source: "server" },
    }
  );

  const [files, filesLoading, filesError] = useCollectionDataOnce(
    query(collection(db, "gallery"), where("type", "==", "file")).withConverter(
      galleryConverter
    ),
    {
      getOptions: { source: "server" },
    }
  );
  const [videos, videosLoading, videosError] = useCollectionDataOnce(
    query(
      collection(db, "gallery"),
      where("type", "==", "video")
    ).withConverter(galleryConverter),
    {
      getOptions: { source: "server" },
    }
  );
  interface dropdownProps {
    id: string;
    emptyValue: string;
    values: [];
  }
  // const CustomDropdown: FunctionComponent<dropdownProps> = (
  //   props: dropdownProps
  // ) => {
  //   return (
  //     <>
  //       <select
  //         className="  border-2 p-2 rounded border-royalblue text-base font-semibold "
  //         name={props.id}
  //         id={props.id}
  //       >
  //         <option value="" disabled selected>
  //           {props.emptyValue}
  //         </option>
  //         <option value="volvo">Volvo</option>
  //         <option value="saab">Saab</option>
  //         <option value="mercedes">Mercedes</option>
  //         <option value="audi">Audi</option>
  //       </select>
  //     </>
  //   );
  // };
  const GalleryImage = ({
    galleryImage,
  }: {
    galleryImage: GalleryInterface;
  }) => {
    return (
      <>
        <div className=" relative bg-white h-72 overflow-clip rounded   transition-all duration-150 ease-in">
          <img
            className=" absolute  w-full  h-full   object-cover"
            key={galleryImage.id}
            alt={galleryImage.title}
            src={galleryImage.file}
          ></img>
          <div className=" w-full h-full hover:bg-[#000000b1] flex  group justify-center items-center bg-transparent relative transition-all duration-150 ease-in">
            <h6 className=" text-white font-medium hidden group-hover:flex transition-all duration-150 ease-in">
              {galleryImage.title}
            </h6>
          </div>
        </div>
      </>
    );
  };
  const GalleryVideo = ({
    galleryImage,
  }: {
    galleryImage: GalleryInterface;
  }) => {
    const [isOpen, setOpen] = useState(false);
    return (
      <>
        <a href={galleryImage.file}>
          <div className=" flex flex-col ">
          <div className=" relative bg-black h-72 overflow-clip rounded  transition-all duration-150 ease-in">
            <img
              className=" absolute  w-full  h-full   object-cover"
              key={galleryImage.id}
              alt={galleryImage.title}
              src={galleryImage.thumbnail}
            ></img>
            <div className=" w-full h-full hover:bg-[#000000b1] flex  group justify-center items-center bg-transparent relative transition-all duration-150 ease-in">
             
            </div>
          </div>
              <h6 className=" text-black font-medium">
                  {galleryImage.title}
                </h6>
            </div>
        
        </a>
      </>
    );
  };
  const { index } = useParams();
  const defaultTabIndex = parseInt(index ?? "0") ?? 0;
  return (
    <>
      <Layout>
        <section className=" min-h-screen  p-4 flex flex-col justify-start items-center pt-32">
          <h1 className=" font-bold text-3xl">Resources</h1>
          <h1 className=" font-semibold text-md  text-zinc-500 py-5">
            Get to know us through visuals and literature
          </h1>
          <Tabs
            defaultIndex={defaultTabIndex}
            className="w-full flex flex-col items-center justify-center"
          >
            <TabList className=" grid grid-cols-3 w-full md:w-5/12 h-16 bg-gray-100 items-center  text-center  justify-center text-black rounded-xl font-semibold ">
              <Tab
                selectedClassName=" bg-royalblue h-full rounded-xl flex justify-center items-center   transition-all duration-300 ease-in text-sunyellow  cursor-pointer"
                className=" bg-gray-100 h-full rounded-xl flex justify-center items-center   transition-all duration-300 ease-in text-black  cursor-pointer"
              >
                <div className=" flex justify-center items-center  w-full h-full">
                  <h3>Photos</h3>
                </div>
              </Tab>
              <Tab
                selectedClassName=" bg-royalblue h-full rounded-xl flex justify-center items-center   transition-all duration-300 ease-in text-sunyellow  cursor-pointer"
                className=" bg-gray-100 h-full rounded-xl flex justify-center items-center   transition-all duration-300 ease-in text-black  cursor-pointer"
              >
                <div className=" flex justify-center items-center  w-full h-full">
                  <h3>Videos</h3>
                </div>
              </Tab>
              <Tab
                selectedClassName=" bg-royalblue h-full rounded-xl flex justify-center items-center   transition-all duration-300 ease-in text-sunyellow  cursor-pointer"
                className=" bg-gray-100 h-full rounded-xl flex justify-center items-center   transition-all duration-300 ease-in text-black  cursor-pointer"
              >
                <div className=" flex justify-center items-center  w-full h-full">
                  <h3>Literature</h3>
                </div>
              </Tab>
            </TabList>
           
            <div className=" my-2">
              <TabPanel>
                {imagesError ? (
                  <ErrorPage></ErrorPage>
                ) : imagesLoading ? (
                  <Loading></Loading>
                ) : (
                  <div className=" h-fit w-screen grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4  px-2 md:px-10 gap-y-1 md:gap-1  items-center ">
                    {/*<div className=" bg-black  md:col-span-2 lg:col-span-3 row-span-2 h-72 md:h-full "></div>*/}

                    {images?.map((file, i) => (
                      <GalleryImage galleryImage={file} />
                    ))}
                  </div>
                )}
              </TabPanel>
              <TabPanel className=" h-fit w-screen grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4  px-2 md:px-10 gap-y-1 md:gap-1  items-center ">
                {videos?.map((file, i) => (
                  <GalleryVideo galleryImage={file} />
                ))}
              </TabPanel>

              <TabPanel className="h-fit w-screen grid  grid-cols-1 md:grid-cols-3 lg:grid-cols-4  justify-center items-center   md:px-10 gap-y-1 md:gap-1   ">
                {files?.map((file, i) => (
                  <a href={file.file}>
                    <div className=" flex flex-col justify-center items-center h-96">
                      <div className=" h-72  w-48 bg-white shadow-md justify-center items-center rounded-lg flex  relative overflow-clip">
                        <h6 className=" text-red-400 font-semibold text-lg">
                          PDF
                        </h6>
                        <img
                          className=" absolute  w-full  h-full   object-cover"
                          key={file.id}
                          alt={file.title}
                          src={file.thumbnail}
                        ></img>
                      </div>
                      <p className=" font-medium text-lg p-4">{file.title}</p>
                    </div>
                  </a>
                ))}
              </TabPanel>
            </div>
          </Tabs>
        </section>
      </Layout>
    </>
  );
};

export default Gallery;
