import { TiSpiral } from "react-icons/ti";
import img from "../../FirstSection/parade.jpg";
export default function OurVision() {
  return (
    <section className="max-w-screen-2xl mx-auto p-4">
      <div className="grid lg:grid-cols-2 h-full justify-center items-center min-h-screen">
        <div className="p-8 flex flex-col gap-8 items-center text-center">
          <h1 className="text-5xl font-bold">About Us</h1>
          <p className="text-xl font-light">
            Intangible cultural heritage refers to practices, representations,
            expressions, knowledge, and know-how that are practiced by
            communities, groups, and /or individuals. These practices are
            recognized as part of the cultural heritage, identity, and
            continuity of concerned communities, and are transmitted from one
            generation to the next. This heritage includes, but is not limited
            to, oral traditions; performing arts; social practices, rituals, and
            festive events; knowledge and practices concerning the nature and
            the universe; and traditional craft.
          </p>
          <span>
            <TiSpiral className="w-12 h-12" />
          </span>
        </div>
        <div className="">
          <img
            src={img}
            alt=""
            className="w-full h-full object-cover shadow-xl rounded-lg"
          />
        </div>
      </div>
      <div className="max-w-2xl mx-auto gap-8 text-center flex flex-col justify-center items-center">
        <h2 className="text-5xl font-bold">Our Vision</h2>
        <p className="text-xl font-light">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod eaque
          nobis animi quasi deleniti dolor dolorum eius amet minima
          exercitationem iste nisi atque veniam ducimus, distinctio nihil?
          Praesentium, quisquam hic.
        </p>
      </div>
    </section>
  );
}
