import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions, Timestamp, WithFieldValue } from "firebase/firestore";

export interface AmbassadorInterface{
    id?: string;
    image?: string;
    name?: string;
    title?: string;
    description?: string;
    missionStatement?: string;
    email?: string;
    phone?: string;
    
}

export const ambassadorConverter: FirestoreDataConverter<AmbassadorInterface> = {
    toFirestore(event: WithFieldValue<AmbassadorInterface>): DocumentData {
      return { image: event.image, title: event.title, description: event.description, };
    },
    fromFirestore(
      snapshot: QueryDocumentSnapshot,
      options: SnapshotOptions
    ): AmbassadorInterface {
      const data = snapshot.data(options);
      return {
        id: snapshot.id,
        image: data.image,
    name: data.name,
    title: data.title,
    description: data.description,
    missionStatement: data.missionStatement,
    email: data.email,
    phone:data.phone
      };
    },
  };
  

