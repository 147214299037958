import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions, Timestamp, WithFieldValue } from "firebase/firestore";

export interface PostInterface{
    id?: string;
    image?: string;
    title?: string;
    category?: string;
    published?: boolean;
    description?: string;
    tags?: Array<string>;
    postDate: Timestamp;
}

export const postConverter: FirestoreDataConverter<PostInterface> = {
    toFirestore(event: WithFieldValue<PostInterface>): DocumentData {
      return { image: event.image, title: event.title, description: event.description, };
    },
    fromFirestore(
      snapshot: QueryDocumentSnapshot,
      options: SnapshotOptions
    ): PostInterface {
      const data = snapshot.data(options);
      return {
        id: snapshot.id,
        image: data.image,
    title: data.title,
    published:data.published,
    description: data.description,
    category: data.category,
    postDate: data.postDate,
    tags: data.tags,
      };
    },
  };
  

