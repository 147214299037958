import { FunctionComponent } from "react";

import { Link } from "react-router-dom";
import ichLongLogoDark from "../resources/ICH Logo Dark.png";
import ichLongLogo from "../resources/ICH Logo Final_FC_2.png";
import ichLongLogoMini from "../resources/ICH Logo Mini.png";

interface imageInterface {
  height: number | 100;
  width: number | 100;
  mini: Boolean | false;
  dark: Boolean | false;
}
const Logo: FunctionComponent<imageInterface> = (props: imageInterface) => {
  return props.mini ? (
    <Link to={"/"}>
      <img
        height={props.height}
        width={props.width}
        alt="Logo"
        src={ichLongLogoMini}
      />
    </Link>
  ) : props.dark ? (
    <Link to={"/"}>
      {" "}
      <img
        height={props.height}
        width={props.width}
        alt="Logo"
        src={ichLongLogoDark}
      />
    </Link>
  ) : (
    <Link to={"/"}>
      {" "}
      <img
        height={props.height}
        width={props.width}
        alt="Logo"
        src={ichLongLogo}
      />
    </Link>
  );
};

export default Logo;
