import Footer from "../components/footer/footer";

import Navbar from "../components/navbar/navbar";

type LayoutProps = {
  children: JSX.Element | JSX.Element[];
};

export default function Layout(props: LayoutProps) {
  return (
    <>
      <Navbar />
      <main className="pt-24">
        {props.children}
        <Footer />
      </main>
    </>
  );
}
