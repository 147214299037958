// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getFirestore} from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyAWbw9IdFDEIj0zDMKKDABTVkVDhoe7F58",
  authDomain: "ich-project-788c7.firebaseapp.com",
  projectId: "ich-project-788c7",
  storageBucket: "ich-project-788c7.appspot.com",
  messagingSenderId: "823179833035",
  appId: "1:823179833035:web:b6e63ee8d3a2d02316754e",
  measurementId: "G-54HN2C4587"
};

// Initialize Firebase

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);