
import InventoryForm from "../components/contact/inventory-form";
import Layout from "./layout";

export default function Contribute() {
  return (
    <Layout>
      <section className="min-h-screen ">
        <div className="py-24">
            <InventoryForm />
        </div>

      </section>
    </Layout>
  );
}
