import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions, Timestamp, WithFieldValue } from "firebase/firestore";



export interface infoInterface{
    image?: string;
    title?: string;
    description?:string;
}

export const infoConverter: FirestoreDataConverter<infoInterface> = {
  toFirestore(event: WithFieldValue<infoInterface>): DocumentData {
      return { };
    },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): infoInterface {
    const data = snapshot.data(options);
    return {
      image: data.image,
      description: data.description,
  title: data.title,
 

    };
  },
};
