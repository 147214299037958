import { AmbassadorInterface } from "../../interfaces/ambassador";
import para from "../../resources/FirstSection/parade.jpg";

const AmbassadorCard = ({
  ambassador,
  onClick,
}: {
  ambassador: AmbassadorInterface;
  onClick: (id: string) => void;
}) => {
  return (
    <div className="overflow-hidden group w-full rounded-xl shadow-sm hover:shadow-lg transition-all duration-300 ease-in ">
      <div className=" bg-black w-full relative aspect-square">
        <img
          className=" absolute  w-full h-full object-cover transition duration-500 group-hover:brightness-50"
          alt={ambassador.title}
          src={ambassador.image ?? para}
        ></img>
        <div className="absolute bottom-0 transition duration-500 delay-500 group-hover:opacity-0 group-hover:hidden p-4 text-white bg-gradient-to-t from-black/80 to-transparent w-full">
          <h6 className=" font-semibold text-lg md:text-xl ">
            {ambassador.name ?? "Title"}
          </h6>
          <h6 className=" font-semibold text-2xl md:text-lg ">
            {ambassador.title ?? "Title"}
          </h6>
        </div>
        <div className="absolute bottom-0 duration-500 transition-transform transform translate-y-[100%] group-hover:translate-y-0 flex flex-col justify-center text-center w-full p-4 text-white">
          <h6 className=" font-semibold text-2xl md:text-lg  ">
            {ambassador.name ?? "Title"}
          </h6>
          <h6 className=" font-semibold text-lg md:text-xl ">
            {ambassador.title ?? "Title"}
          </h6>
          <p className=" font-normal text-base  ">
            {ambassador.description ??
              "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex, tenetur enim voluptate facilis perferendis voluptatibus officiis ad amet corrupti nihil ipsa earum, velit doloremque soluta accusantium quis dicta rem est!"}
          </p>
          <button
            onClick={() => {
              onClick(ambassador.id!);
            }}
            className=" px-8 py-2 bg-royalblue flex justify-center items-center  mt-2 text-lg font-semibold text-white rounded-full"
          >
            View profile
          </button>
        </div>
      </div>
    </div>
  );
};

export default AmbassadorCard;
