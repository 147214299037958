import { Link } from "react-router-dom";
import { CategoryInterface } from "../../interfaces/category";
import para from "../../resources/FirstSection/parade.jpg";

const CategoryCard = (props: CategoryInterface) => {
  return (
    <Link to={`../categories/${props.id ?? "Title"}/${props.title}`}>
      <div className=" group text-white aspect-square lg:aspect-[9/16] bg-black  rounded-xl shadow-sm hover:shadow-lg overflow-hidden  transition-all duration-300 ease-in relative">
        <img
          className="w-full  h-full opacity-60  object-cover"
          alt="Parade"
          src={props.image ?? para}
        ></img>
        <div className="absolute top-0 p-4 flex w-full justify-end h-full flex-col">
          <div className=" w-fit inline-grid grid-cols-1">
            <h6 className="relative font-bold text-2xl border-b-0 text-ellipsis transition-all duration-100 ease-out ">
              {props.title ?? "Title"}
            </h6>
            <div className=" transition-all duration-200 ease-in   h-1 group-hover:w-full  w-0  bg-slate-50"></div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default CategoryCard;
