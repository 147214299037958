import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions, Timestamp, WithFieldValue } from "firebase/firestore";

export interface CategoryInterface{
    id?: string
    image?: string;
    title?: string;
    
}

export const categoryConverter: FirestoreDataConverter<CategoryInterface> = {
    toFirestore(category: WithFieldValue<CategoryInterface>): DocumentData {
      return { image: category.image, title: category.title,  };
    },
    fromFirestore(
      snapshot: QueryDocumentSnapshot,
      options: SnapshotOptions
    ): CategoryInterface {
      const data = snapshot.data(options);
      return {
 
        image: data.image,
    title: data.title,
    
      };
    },
  };
  

