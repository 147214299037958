import { collection } from "firebase/firestore";
import { FunctionComponent, useState } from "react";
import { useCollectionOnce } from "react-firebase-hooks/firestore";
import {
  AmbassadorInterface,
  ambassadorConverter,
} from "../../interfaces/ambassador";
import para from "../../resources/FirstSection/parade.jpg";
import { db } from "../../services/firebaseconfig";
import AmbassadorCard from "./ambassadorcard";

const AmbassadorSection: FunctionComponent = () => {
  const [currentAmbassador, setCurrentAmbassador] =
    useState<AmbassadorInterface | null>(null);
  const [value, loading, error] = useCollectionOnce(
    collection(db, "ambassadors").withConverter(ambassadorConverter),
    {
      getOptions: { source: "server" },
    }
  );

  const showAmbassador = (id: string) => {
    let selected = value?.docs
      .map((doc) => ({ id: doc.id, ...doc.data() }))
      .filter((ambassador) => ambassador.id === id)[0] as AmbassadorInterface;
    console.log(selected);
    setCurrentAmbassador(selected);
  };
  return (
    <section className="gap-4 max-w-screen-2xl mx-auto px-4 py-20">
      <div className=" flex flex-col   justify-center items-center  my-4">
        <h2 className="text-5xl font-bold">Meet Our Team</h2>
        <h6 className=" font-semibold text-md  text-zinc-500 py-5">
          Get to know the team.
        </h6>
      </div>
      <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 items-start gap-12">
        {value?.docs.map((doc, index) => (
          <AmbassadorCard
            key={doc.id}
            ambassador={{ id: doc.id, ...doc.data() }}
            onClick={showAmbassador}
          />
        ))}
      </div>
      {currentAmbassador && (
        <div
          className="fixed transition inset-0 bg-black/30 flex items-center justify-center pt-48 md:pt-0"
          onClick={() => setCurrentAmbassador(null)}
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="max-w-3xl h-screen md:h-auto w-full bg-white  rounded-lg overflow-hidden grid lg:grid-cols-3 overflow-y-auto"
          >
            <div className="flex flex-col items-center gap-2 p-4">
              <img
                src={para}
                alt=""
                className="w-full aspect-square object-cover rounded-md"
              />
              <h3 className="text-lg font-semibold text-center">
                {currentAmbassador.name}
              </h3>
              <h4 className="text-xl font-semibold text-[#1a2a75]">
                {currentAmbassador.title}
              </h4>
            </div>
            <div className="lg:max-h-[80vh] overscroll-y-auto p-4 bg-slate-200 lg:col-span-2">
              <h3 className="text-3xl font-semibold">Biography</h3>
              <p>{currentAmbassador.description}</p>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default AmbassadorSection;
