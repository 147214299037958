import { FormEventHandler } from "react";
import { AiOutlineSearch } from "react-icons/ai";

const SearchBar = () => {
  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    console.log(event.currentTarget.search.value);
  };

  return (
    // <div className="flex lg:justify-center  w-full hover:justify-start lg:w-56 focus:lg:w-56 focus:justify-start transition-all  ease-in-out duration-300 group ">
    <form
      className=" flex items-center p-0 w-full relative text-black"
      onSubmit={handleSubmit}
      action=""
    >
      <AiOutlineSearch className="w-6 h-6 absolute left-2" />
      <input
        className="py-2  bg-slate-100  w-full  border-royalblue placeholder:font-medium pl-10 rounded-full focus:outline-none"
        type="text"
        placeholder="Search"
        id="search"
      />
    </form>
    // </div>
  );
};

export default SearchBar;
