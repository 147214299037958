import AboutSection from "../components/home/aboutsection";
import BlogArticles from "../components/home/blog-articles";
import Hero from "../components/home/hero";
import WhatsNewSection from "../components/home/upcoming-events";

import Layout from "./layout";

export default function Home() {
  return (
    <Layout>
      <Hero />
      <AboutSection />
      <WhatsNewSection />
      <BlogArticles />
    </Layout>
  );
}
