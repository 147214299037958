import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions, Timestamp, WithFieldValue } from "firebase/firestore";
import { type } from "os";

export interface GalleryInterface{

    id?: string;
    file?: string;
    thumbnail?: string;
    title?: string;
    type?: string;
    year_of_inscription?: string;
    ich_elements?: string;
    createdOn: Date;

}


  

export const galleryConverter: FirestoreDataConverter<GalleryInterface> = {
    toFirestore(event: WithFieldValue<GalleryInterface>): DocumentData {
        return { };
      },
    fromFirestore(
      snapshot: QueryDocumentSnapshot,
      options: SnapshotOptions
    ): GalleryInterface {
      const data = snapshot.data(options);
      return {
        id: snapshot.id,
        file: data.file,
    title: data.title,
    type: data.type,
    thumbnail: data.thumbnail,
    year_of_inscription: data.year_of_inscription,
    ich_elements: data.year_of_inscription,
    createdOn: data.createdOn,


      };
    },
  };
  