import { FunctionComponent } from "react";
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";
import Logo from "../logo";

const Footer: FunctionComponent = () => {
  return (
    <div className=" w-screen  bg-royalblue   text-white  px-4 py-20 bottom-0 ">
      <div className=" grid grid-cols-2 gap-12  lg:grid-cols-5  w-full">
        <div className=" flex justify-center items-center col-span-2 lg:col-span-1">
          {" "}
          <Logo dark={true} height={200} width={200} mini={true}></Logo>
        </div>
        <div className=" flex flex-col justify-start overflow-hidden overflow-ellipsis ">
          <h6 className=" font-semibold text-white">Contact Us</h6>
          <p className="  text-white py-2">
            Intangible Cultural Heritage, Ground Floor East Wing, Sky Mall,
            Haggatt Hall, St.Michael Barbados{" "}
          </p>
          <a href="ich.committee@barbados.gov.bb" className="py-2 ">
            ich.committee@barbados.gov.bb{" "}
          </a>
          <a href="tel:+1(246)535-5846" className=" py-2 ">
            +1(246)535-5859
          </a>
        </div>
        <div className=" flex flex-col justify-start ">
          <h6 className=" font-semibold text-white">ICH Domains</h6>
          <a href="/" className="  py-2">
            Oral traditions and expressions{" "}
          </a>
          <a href="/" className="  py-2">
            Performing Arts
          </a>
          <a href="/" className="  py-2">
            Social practices, rituals, and festive events
          </a>
          <a href="/" className="  py-2">
            Knowledge and practices concerning nature and the universe
          </a>
        </div>
        <div className=" flex flex-col justify-start  ">
          <h6 className="  text-white font-semibold">Resources</h6>
          <a href="https://www.unesco.org/en" target="_blank" rel="noopener noreferrer" className="  py-2">
            UNESCO
          </a>
          <a href="/" className="  py-2">
            BMHS
          </a>
          <a href="https://ncf.bb" target="_blank" rel="noopener noreferrer" className="  py-2">
            NCF
          </a>
        </div>
        <div className=" flex flex-col justify-start  ">
          <h6 className=" font-semibold text-white">Media</h6>
          <Link to="/resources/0" className=" text-white py-2">
            Images
          </Link>
          <Link to="/resources/1" className=" text-white py-2">
            Videos
          </Link>
          <Link to="/resources/2" className=" text-white py-2">
            Literature
          </Link>
        </div>
      </div>

      <div className=" flex flex-col  w-full items-center p-4 text-white">
        <div className=" flex ">
          <a href="/" className=" p-4">
            {" "}
            <FaFacebookF className="h-6 w-6"></FaFacebookF>{" "}
          </a>
          <a href="https://www.instagram.com/culturebarbados" target="_blank" rel="noopener noreferrer" className=" p-4 ">
            {" "}
            <FaInstagram className="h-6 w-6"></FaInstagram>
          </a>
          <a href="/" className=" p-4">
            {" "}
            <FaTwitter className="h-6 w-6"></FaTwitter>
          </a>
        </div>
        <div className=" flex  justify-evenly ">
          <Link to="/" className=" text-white">
            Privacy Policy
          </Link>
          <p className="px-2"> | </p>
          <Link to="/" className=" text-white">
            Terms & Conditions
          </Link>
          <p className="px-2"> | </p>
          <Link to="/" className=" text-white">
            Cookie Policy
          </Link>
        </div>
        <div className=" flex font-thin  ">
          <p>
            Copyright ©2024 Intangible Cultural Heritage Barbados , Inc. All
            rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
