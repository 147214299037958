import { zodResolver } from "@hookform/resolvers/zod";
import axios from "axios";
import { useForm } from "react-hook-form";
import {
  FaFacebookF,
  FaInbox,
  FaInstagram,
  FaMapMarkerAlt,
  FaPhone,
  FaTwitter,
} from "react-icons/fa";

import * as z from "zod";
import para from "../../FirstSection/parade.jpg";

const formContactSchema = z.object({
  name: z.string().min(1, { message: "Name is required" }),
  email: z.string().email("Invalid email address"),
  phone: z
    .string()
    .regex(/\b(?:\+\d{1,2}[-. ]?)?(\d{3}[-. ]?)?\d{3}[-. ]?\d{4}\b/, {
      message: "Invalid telephone number",
    }),
  subject: z.string().min(1, { message: "Subject is required" }),
  message: z.string().min(1, { message: "Message is required" }),
});

export default function ContactForm() {
  const form = useForm<z.infer<typeof formContactSchema>>({
    resolver: zodResolver(formContactSchema),
    defaultValues: {
      name: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
    },
  });

  const { isSubmitting, isValid, errors } = form.formState;

  const onContactSubmit = async (values: z.infer<typeof formContactSchema>) => {
    try {
      await axios.post("/api/contact.ts", values);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className=" shadow bg-white rounded-lg overflow-hidden grid md:grid-cols-2 max-w-3xl mx-auto">
      <div
        style={{ backgroundImage: `url(${para})` }}
        className=" text-white h-auto md:h-full flex flex-col justify-between items-center bg-cover bg-center"
      >
        <div className=" h-full  flex flex-col gap-4 justify-between  p-4 items-center bg-black/50">
          <h1 className=" font-semibold text-lg">Contact Information</h1>
          <div className="text-left space-y-8">
            <div className="flex items-center gap-2 w-full">
              <FaInbox className="h-4 w-4"></FaInbox>
              <h1 className=" font-semibold text-md">Email.Mail.com</h1>
            </div>
            <div className="flex items-center gap-2 w-full">
              <FaPhone className="h-4 w-4"></FaPhone>
              <h1 className=" font-semibold text-md">+1 (246) 535-5859</h1>
            </div>
            <div className="flex gap-2 w-full">
              <FaMapMarkerAlt className="h-6 w-6"></FaMapMarkerAlt>
              <p className=" font-semibold text-md">
                Ground Floor East Wing, Sky Mall Haggatt Hall, St.Michael
                Barbados
              </p>
            </div>
          </div>
          <div>
            <h1 className=" font-semibold text-md text-center">Socials</h1>
            <div className=" flex ">
              <a className=" p-4">
                {" "}
                <FaFacebookF className="h-6 w-6" />{" "}
              </a>
              <a className=" p-4 ">
                {" "}
                <FaInstagram className="h-6 w-6" />
              </a>
              <a className=" p-4">
                {" "}
                <FaTwitter className="h-6 w-6" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className=" bg-white text-black flex flex-col p-4 items-center relative">
        <h1 className=" font-bold text-lg">Shoot Us A Message</h1>
        <form
          onSubmit={form.handleSubmit(onContactSubmit)}
          className=" w-full form flex flex-col h-full text-left"
        >
          <div>
            <input
              className=" mt-2 block w-full h-10 p-4 border-b-2 border-black focus:outline-none"
              {...form.register("name")}
              type="text"
              placeholder="Name"
              id=""
            />
            {errors?.name && <span>{errors?.name?.message}</span>}
          </div>
          <div>
            <input
              className=" mt-2 block w-full h-10 p-4  border-b-2 border-black focus:outline-none"
              {...form.register("email")}
              type="text"
              placeholder="Email"
              id=""
            />
            {errors?.email && <span>{errors?.email?.message}</span>}
          </div>
          <div>
            <input
              className=" mt-2 block w-full h-10 p-4  border-b-2 border-black focus:outline-none"
              {...form.register("phone")}
              type="text"
              placeholder="Phone"
              id=""
            />
            {errors?.phone && <span>{errors?.phone?.message}</span>}
          </div>
          <div>
            <input
              className=" mt-2 block w-full h-10 p-4  border-b-2 border-black focus:outline-none"
              type="text"
              {...form.register("subject")}
              placeholder="Subject"
              id=""
            />
            {errors?.subject && <span>{errors?.subject?.message}</span>}
          </div>
          <div>
            <textarea
              className="form-textarea mt-1 block w-full p-4  border-b-2 border-black focus:outline-none"
              placeholder="Message.."
              {...form.register("message")}
              id=""
              cols={30}
              rows={5}
            />
            {errors?.message && <span>{errors?.message?.message}</span>}
          </div>
          <button
            type="submit"
            className="  w-full p-4 bg-royalblue text-white rounded-full mt-4 "
          >
            Send message
          </button>
        </form>
      </div>
    </div>
  );
}
