import {
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  Timestamp,
  WithFieldValue,
} from "firebase/firestore";

export interface EventsInterface {
  id?: string;
  image?: string;
  title?: string;
  author?: string;
  published?: boolean;
  description?: string;
  tags?: Array<string>;
  eventDate: Timestamp;
  postDate: Timestamp;
}

export const eventConverter: FirestoreDataConverter<EventsInterface> = {
  toFirestore(event: WithFieldValue<EventsInterface>): DocumentData {
    return {
      image: event.image,
      title: event.title,
      description: event.description,
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): EventsInterface {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      image: data.image,
      title: data.title,
      author: data.author,
      published: data.published,
      description: data.description,
      eventDate: data.eventDate,
      postDate: data.postDate,
      tags: data.tags,
    };
  },
};
