import { FunctionComponent } from "react";
import Logo from "./logo";

const Loading: FunctionComponent= ()=>{

    return <>
        <div className=" h-screen flex justify-center items-center">
            <div className=" animate-pulse">
            <Logo mini={true} dark={true}  width={200} height={200}></Logo>

            </div>
        </div>
    </>

}


export default Loading;