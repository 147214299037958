import { FunctionComponent, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { BiMenuAltLeft } from "react-icons/bi";
import { VscClose } from "react-icons/vsc";
import Logo from "../logo";
import SearchBar from "./navbar_search";

const navlinks = [
  {
    name: "Home",
    path: "/",
  },
  {
    name: "Our Living Heritage",
    path: "/categories",
  },
  //{
    //name: "Events",
    //path: "/events",
  //},
  {
    name: "About",
    path: "/aboutus",
  },
  {
    name: "Resources",
    path: "/resources",
  },
  {
    name: "Contribute",
    path: "/contribute"
  },
  {
    name: "2003 Convention",
    //path: "/convention"
    path: "/"
  },
  {
    name: "Contact Us",
    path: "/contactus",
  },
];

const Navbar: FunctionComponent = () => {
  const [show, setShow] = useState<boolean>(false);
  const [scroll, setScroll] = useState(false);

  const router = useLocation();

  // const changeBackground = () => {
  //   if (window.scrollY >= 80) {
  //     setScroll(true);
  //   } else {
  //     setScroll(false);
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", changeBackground);
  // });

  const onClick = () => {
    setShow((state) => !state);
  };

  const isActive = (href: string) => {
    return (
      (router.pathname === "/" && href === "/") ||
      router.pathname === href ||
      router.pathname?.startsWith(`${href}/`)
    );
  };

  return (
    <>
      <div
        className={`w-screen items-center justify-between shadow-sm top-0 fixed z-50 flex whitespace-nowrap pr-4 xl:pl-4 xl:pr-12 ${
          "bg-white text-black"
        } text-black transition-all ease-in duration-300`}
      >
        <div className=" ">
          <Logo dark={true} mini={false} height={100} width={220} />
        </div>
        <div className="flex xl:hidden">
          <button onClick={onClick}>
            {show ? (
              <VscClose className="w-10 h-10 text-black" />
            ) : (
              <BiMenuAltLeft className="w-10 h-10" />
            )}
          </button>
        </div>
        <div className=" font-semibold xl:flex gap-x-4 items-center  text-sm  hidden text-center ">
          {navlinks.map((link) => (
            <Link
              key={link.path}
              to={link.path}
              className={`px-8 py-4 ${
                isActive(link.path) && "bg-[#1a2a75] text-white"
              }`}
            >
              {link.name}
            </Link>
          ))}

          <div>
            <SearchBar />
          </div>
        </div>
      </div>
      <div
        className={`xl:hidden font-semibold transition-transform transform duration-300 flex h-full ${
          show ? "translate-x-0" : "-translate-x-[100%]"
        }  flex-col text-lg w-full pt-32 bg-white fixed z-20 overflow-hidden px-4`}
      >
        <SearchBar />
        {navlinks.map((link) => (
          <Link
            key={link.path}
            to={link.path}
            className={`px-8 py-4 w-full ${
              router.pathname === link.path && "bg-[#1a2a75] text-white"
            }`}
          >
            {link.name}
          </Link>
        ))}
      </div>
    </>
  );
};

export default Navbar;
