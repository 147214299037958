import { collection } from "firebase/firestore";
import { useCollectionOnce } from "react-firebase-hooks/firestore";
import { AiOutlineSearch } from "react-icons/ai";
import paradeImg from "../../FirstSection/parade.jpg";
import ErrorPage from "../../components/error";
import EventCard from "../../components/events/eventcard";
import Footer from "../../components/footer/footer";
import Loading from "../../components/loading";
import Navbar from "../../components/navbar/navbar";
import { eventConverter } from "../../interfaces/events";
import { db } from "../../services/firebaseconfig";
const Events = () => {
  const [value, loading, error] = useCollectionOnce(
    collection(db, "events").withConverter(eventConverter),
    {
      getOptions: { source: "server" },
    }
  );
  return (
    <>
      <Navbar />

      {error ? (
        <ErrorPage></ErrorPage>
      ) : loading ? (
        <Loading />
      ) : (
        <main className="pt-24">
          <section className="">
            <div className=" h-[40vh] bg-black relative overflow-hidden w-full">
              <img
                className="w-full h-full object-cover object-[50%_20%]"
                alt="Parade"
                src={paradeImg}
              />

              <div
                className={` absolute top-0 bg-black/50 text-white flex flex-col justify-center items-center h-full  w-full 
               `}
              >
                <div className=" w-fit">
                  <h1 className="  text-5xl font-bold">Events</h1>
                </div>
              </div>
            </div>
            <div className="max-w-screen-2xl mx-auto p-4 space-y-4">
              <form
                className=" flex items-center w-full xl:w-1/3 relative "
                action=""
              >
                <AiOutlineSearch className="w-6 h-6 absolute left-2" />
                <input
                  className="py-2  bg-slate-100  w-full  border-royalblue placeholder:font-medium pl-10 rounded-full focus:outline-none"
                  type="text"
                  placeholder="Search"
                  id="search"
                />
              </form>

              <div className=" grid md:grid-cols-3  lg:grid-cols-4 xl:grid-cols-5 gap-4 w-full min-h-screen ">
                {value?.docs.length ? (
                  value?.docs.map((doc) => (
                    <EventCard
                      key={doc.id}
                      id={doc.id}
                      tags={doc.data().tags}
                      title={doc?.data().title ?? "Title"}
                      description={doc?.data().description}
                      eventDate={doc?.data().eventDate}
                      postDate={doc?.data().postDate}
                      image={doc?.data().image ?? ""}
                    />
                  ))
                ) : (
                  <div className="md:col-span-2 lg:col-span-3 xl:col-span-5 flex py-8 px-4">
                    <span className="mx-auto">No events listed yet</span>
                  </div>
                )}
              </div>
            </div>
          </section>
        </main>
      )}
      <Footer></Footer>
    </>
  );
};

export async function getStaticProps(context: any) {
  return {
    props: {}, // will be passed to the page component as props
  };
}

export default Events;
