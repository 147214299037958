import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
// import axios from "axios";
import { useForm } from "react-hook-form";
import * as z from "zod";

const formSchema = z.object({
  name: z.string().min(1, { message: "Field is required" }),
  telephone: z
    .string()
    .regex(/\b(?:\+\d{1,2}[-. ]?)?(\d{3}[-. ]?)?\d{3}[-. ]?\d{4}\b/),
  address: z.string().min(1, { message: "Field is required" }),
  email: z.string().email(),
  purpose: z.string().min(1, { message: "Field is required" }),
  name_of_practice: z.string().min(1, { message: "Field is required" }),
  name_of_body: z.string().optional(),
  name_of_contact_person: z.string().min(1, { message: "Field is required" }),
  contact_number: z
    .string()
    .regex(/\b(?:\+\d{1,2}[-. ]?)?(\d{3}[-. ]?)?\d{3}[-. ]?\d{4}\b/),
  contact_email: z.string().email(),
  contact_address: z.string().min(1, { message: "Field is required" }),
  where_practice_exercised: z.string().min(1, { message: "Field is required" }),
  practice_description: z.string().min(1, { message: "Field is required" }),
  history_of_practice: z.string().min(1, { message: "Field is required" }),
  why_practice_important: z.string().min(1, { message: "Field is required" }),
  how_practice_taught: z.string().min(1, { message: "Field is required" }),
  practice_frequency: z.string().min(1, { message: "Field is required" }),
  challenges_to_practice: z.string().min(1, { message: "Field is required" }),
  additional_info: z.string().optional(),
  agreement: z.boolean().default(false),
});
export default function InventoryForm() {
  
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      telephone: "",
      address: "",
      email: "",
      purpose: "",
      name_of_practice: "",
      name_of_body: "",
      name_of_contact_person: "",
      contact_number: "",
      contact_email: "",
      contact_address: "",
      where_practice_exercised: "",
      practice_description: "",
      history_of_practice: "",
      why_practice_important: "",
      how_practice_taught: "",
      practice_frequency: "",
      challenges_to_practice: "",
      additional_info: "",
      agreement: false,
    },
    mode: "onTouched",
  });

  const { isSubmitting, isValid, errors } = form.formState;

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    console.log("submitted", values);
    // TODO: send to server
  };

  return (
    <div className=" shadow bg-white rounded-lg overflow-hidden p-4 max-w-3xl w-full mx-auto">
      <form onSubmit={form.handleSubmit(onSubmit)} className="text-left">
        <h1 className="text-lg font-bold text-center">
          THE BARBADOS INTANGIBLE CULTURAL HERITAGE INVENTORY FORM
        </h1>
        
          <div className={`space-y-6 text-left `}>
            <p>
              Use this form to propose a new intangible cultural heritage
              element for the Barbados National Inventory of Intangible Cultural
              Heritage or provide additional information on elements on the
              National Inventory. Completed forms can be submitted to the
              Barbados National Intangible Cultural Heritage Committee via email
              or hand delivery:
            </p>
            <p>Telephone: 1 246 535-5859</p>
            <p>Email: PMO.ICHCommittee@barbados.gov.bb</p>
            <p>
              Address: Ground Floor, East Wing, Sky Mall, Haggatt Hall ST.
              MICHAEL
            </p>
          </div>
 
        
          <div className={`space-y-4 text-left `}>
            <p>
              Intangible cultural heritage refers to practices, representations,
              expressions, knowledge, and know-how that are practiced by
              communities, groups, and /or individuals. These practices are
              recognized as part of the cultural heritage, identity, and
              continuity of concerned communities, and are transmitted from one
              generation to the next. This heritage includes, but is not limited
              to, oral traditions; performing arts; social practices, rituals,
              and festive events; knowledge and practices concerning the nature
              and the universe; and traditional craft.
            </p>
            <p>
              Submitted reports of intangible cultural heritage in Barbados will
              be reviewed by the ICH Committee. Elements that satisfy the
              criteria for the Barbados National Inventory of Intangible
              Cultural Heritage will be added to the Barbados National Inventory
              of Intangible Cultural Heritage.
            </p>
            <p>
              Further information can be found on the website of the National
              Intangible Cultural Heritage Committee – Website
            </p>
          </div>

        
          <div
            className={`w-full grid xl:grid-cols-2 gap-4 `}
          >
            <h1 className="xl:col-span-2 font-semibold">
              Information of Person Submitting the Inventory Form
            </h1>
            <div className="flex flex-col gap-2">
              <label htmlFor="name">Name</label>
              <input
                className="py-2 px-4 bg-slate-100"
                type="text"
                {...form.register("name")}
              />
              {errors?.name && (
                <span className=" text-red-500">{errors.name.message}</span>
              )}
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="address">Address</label>
              <input
                className="py-2 px-4 bg-slate-100"
                type="text"
                {...form.register("address")}
              />
              {errors?.address && (
                <span className=" text-red-500">{errors.address.message}</span>
              )}
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="address">Telephone</label>
              <input
                className="py-2 px-4 bg-slate-100"
                type="text"
                {...form.register("telephone")}
              />
              {errors?.telephone && (
                <span className=" text-red-500">
                  {errors.telephone.message}
                </span>
              )}
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="address">Email</label>
              <input
                className="py-2 px-4 bg-slate-100"
                type="text"
                {...form.register("email")}
              />
              {errors?.email && (
                <span className=" text-red-500">{errors.email.message}</span>
              )}
            </div>
            <h1 className="xl:col-span-2 font-semibold">
              Purpose of Submission
            </h1>
            <div className="flex items-center gap-2">
              <label htmlFor="purpose">Proposal for new element</label>
              <input
                className="py-2 px-4 bg-slate-100"
                type="radio"
                value={"Proposal for new element"}
                {...form.register("purpose")}
              />
            </div>
            <div className="flex items-center gap-2">
              <label htmlFor="purpose">
                Proposal for updating inventoried element
              </label>
              <input
                className="py-2 px-4 bg-slate-100"
                type="radio"
                value={"Proposal for updating inventoried element"}
                {...form.register("purpose")}
              />
            </div>
            {errors?.purpose && (
              <span className="col-span-2 text-red-500">
                {errors.purpose.message}
              </span>
            )}
          </div>


          <div
            className={`w-full grid xl:grid-cols-2 gap-4 `}
          >
            <h1 className="xl:col-span-2 font-semibold">Name of practice</h1>
            <div className="flex flex-col xl:col-span-2 gap-2">
              <input
                className="py-2 px-4 bg-slate-100"
                type="text"
                {...form.register("name_of_practice")}
              />
              {errors?.name_of_practice && (
                <span className=" text-red-500">
                  {errors.name_of_practice.message}
                </span>
              )}
            </div>
            <h1 className="xl:col-span-2 font-semibold">
              Name of communities / groups / individuals / organisation
              concerned with the practice
            </h1>
            <div className="flex flex-col gap-2">
              <label htmlFor="name_of_body">Name of body (if applicable)</label>
              <input
                className="py-2 px-4 bg-slate-100"
                type="text"
                {...form.register("name_of_body")}
              />
              {errors?.name_of_body && (
                <span className=" text-red-500">
                  {errors.name_of_body.message}
                </span>
              )}
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="name_of_contact_person">
                Name of contact person
              </label>
              <input
                className="py-2 px-4 bg-slate-100"
                type="text"
                {...form.register("name_of_contact_person")}
              />
              {errors?.name_of_contact_person && (
                <span className=" text-red-500">
                  {errors.name_of_contact_person.message}
                </span>
              )}
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="contact_number">Telephone number</label>
              <input
                className="py-2 px-4 bg-slate-100"
                type="text"
                {...form.register("contact_number")}
              />
              {errors?.contact_number && (
                <span className=" text-red-500">
                  {errors.contact_number.message}
                </span>
              )}
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="contact_email">Email Address</label>
              <input
                className="py-2 px-4 bg-slate-100"
                type="text"
                {...form.register("contact_email")}
              />
              {errors?.contact_email && (
                <span className=" text-red-500">
                  {errors.contact_email.message}
                </span>
              )}
            </div>
            <div className="flex flex-col gap-2 xl:col-span-2">
              <label htmlFor="contact_address">Address</label>
              <input
                className="py-2 px-4 bg-slate-100"
                type="text"
                {...form.register("contact_address")}
              />
              {errors?.contact_address && (
                <span className=" text-red-500">
                  {errors.contact_address.message}
                </span>
              )}
            </div>
          </div>
        
          <div
            className={`w-full grid xl:grid-cols-2 gap-4`}
          >
            <h1 className="xl:col-span-2 font-semibold">
              Where is the practice exercised?
            </h1>
            <div className="flex flex-col gap-2 xl:col-span-2">
              <textarea
                rows={5}
                placeholder="Click or tap here to enter text"
                className="py-2 px-4 bg-slate-100"
                {...form.register("where_practice_exercised")}
              />
              {errors?.where_practice_exercised && (
                <span className=" text-red-500">
                  {errors.where_practice_exercised.message}
                </span>
              )}
            </div>
            <h1 className="xl:col-span-2 font-semibold">
              Concise description of the practice at this present time:{" "}
              <span className="font-normal">
                (What? Who? Where? How? When? What is the Process?)
              </span>
            </h1>
            <div className="flex flex-col gap-2 xl:col-span-2">
              <textarea
                rows={5}
                placeholder="Click or tap here to enter text"
                className="py-2 px-4 bg-slate-100"
                {...form.register("practice_description")}
              />
              {errors?.practice_description && (
                <span className=" text-red-500">
                  {errors.practice_description.message}
                </span>
              )}
            </div>
          </div>


          <div
            className={`w-full grid xl:grid-cols-2 gap-4 `}
          >
            <h1 className="xl:col-span-2 font-semibold">
              Concise description of the history of the practice:
            </h1>
            <div className="flex flex-col gap-2 xl:col-span-2">
              <textarea
                rows={5}
                placeholder="Click or tap here to enter text"
                className="py-2 px-4 bg-slate-100"
                {...form.register("history_of_practice")}
              />
              {errors?.history_of_practice && (
                <span className=" text-red-500">
                  {errors.history_of_practice.message}
                </span>
              )}
            </div>
            <h1 className="xl:col-span-2 font-semibold">
              Why is the practice important?
            </h1>
            <div className="flex flex-col gap-2 xl:col-span-2">
              <textarea
                rows={5}
                placeholder="Click or tap here to enter text"
                className="py-2 px-4 bg-slate-100"
                {...form.register("why_practice_important")}
              />
              {errors?.why_practice_important && (
                <span className=" text-red-500">
                  {errors.why_practice_important.message}
                </span>
              )}
            </div>
          </div>

     
          <div
            className={`w-full grid xl:grid-cols-2 gap-4 `}
          >
            <h1 className="xl:col-span-2 font-semibold">
              How is the practice taught to others?
            </h1>
            <div className="flex flex-col gap-2 xl:col-span-2">
              <textarea
                rows={5}
                placeholder="Click or tap here to enter text"
                className="py-2 px-4 bg-slate-100"
                {...form.register("how_practice_taught")}
              />
              {errors?.how_practice_taught && (
                <span className=" text-red-500">
                  {errors.how_practice_taught.message}
                </span>
              )}
            </div>
            <h1 className="xl:col-span-2 font-semibold">
              How often is the practice exercised?
            </h1>
            <div className="flex flex-col gap-2 xl:col-span-2">
              <textarea
                rows={5}
                placeholder="Click or tap here to enter text"
                className="py-2 px-4 bg-slate-100"
                {...form.register("practice_frequency")}
              />
              {errors?.practice_frequency && (
                <span className=" text-red-500">
                  {errors.practice_frequency.message}
                </span>
              )}
            </div>
          </div>


          <div
            className={`w-full grid xl:grid-cols-2 gap-4 `}
          >
            <h1 className="xl:col-span-2 font-semibold">
              What challenges threaten continued enactment of the practice?
            </h1>
            <div className="flex flex-col gap-2 xl:col-span-2">
              <textarea
                rows={5}
                placeholder="Click or tap here to enter text"
                className="py-2 px-4 bg-slate-100"
                {...form.register("challenges_to_practice")}
              />
              {errors?.challenges_to_practice && (
                <span className=" text-red-500">
                  {errors.challenges_to_practice.message}
                </span>
              )}
            </div>
            <h1 className="xl:col-span-2 font-semibold">
              Additional information
            </h1>
            <div className="flex flex-col gap-2 xl:col-span-2">
              <textarea
                rows={5}
                placeholder="Click or tap here to enter text"
                className="py-2 px-4 bg-slate-100"
                {...form.register("additional_info")}
              />
              {errors?.additional_info && (
                <span className=" text-red-500">
                  {errors.additional_info.message}
                </span>
              )}
            </div>
          </div>

          <div
            className={`w-full grid xl:grid-cols-2 gap-4 `}
          >
            <h1 className="xl:col-span-2 font-semibold">User Agreement</h1>
            <div className="flex flex-col gap-2 xl:col-span-2">
              <div className="">
                <input
                  type="checkbox"
                  placeholder="Click or tap here to enter text"
                  className="py-2 px-4 bg-slate-100"
                  {...form.register("agreement")}
                />{" "}
                I have read the “Personal Information Collection and Privacy
                Policy Statement” stated below and agreed to provide my personal
                data for reporting and research on the intangible cultural
                heritage in Barbados. (Please tick the box for confirmation)
              </div>
              <h1 className="underline font-semibold">
                Personal Information Collection and Privacy Policy Statement
              </h1>
              <p>
                You have the right to request access to and correction of
                personal data as stated in this form. Personal data provided in
                the reporting form will be used only for reporting and research
                on the intangible cultural heritage in Barbados. Enquiries
                concerning the personal data collected in this form, including
                the request for access and correction should be addressed to the
                National Intangible Cultural Heritage Committee.
              </p>
              {errors?.agreement && (
                <span className=" text-red-500">
                  {errors.agreement.message}
                </span>
              )}
            </div>
          </div>
      
        <div className="flex gap-4 pt-4">
      
          
            <button
              type="submit"
              className={`py-2 px-4 bg-[#1a2a75] text-white `}
              disabled={isSubmitting || !isValid}
            >
              Submit
            </button>
       
        </div>
      </form>
    </div>
  );
}
