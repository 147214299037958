import { ArrowForward } from "@mui/icons-material";
import { collection, limit, query } from "firebase/firestore";
import { useCollectionDataOnce } from "react-firebase-hooks/firestore";
import { Link } from "react-router-dom";
import { PostInterface, postConverter } from "../../interfaces/post";
import { db } from "../../services/firebaseconfig";
import BlogCard from "../categories/blogcard";

export default function BlogArticles() {
  const [value, loading, error] = useCollectionDataOnce(
    query(collection(db, "posts"), limit(5)).withConverter(postConverter),
    {
      getOptions: { source: "server" },
    }
  );
  // const [value, loading, error] = useDocumentDataOnce(
  //   doc(db, `posts`).withConverter(postConverter),
  //   {
  //     getOptions: { source: "server" },
  //   }
  // );
  return (
    <section className="py-20 px-8 md:px-28">
      <div className="max-w-screen-2xl mx-auto flex flex-col gap-y-12">
        <div className="flex justify-between">
          <div className="flex flex-col gap-y-4 w-full">
            <div className="flex justify-between">
              <h3 className="font-semibold text-3xl md:text-4xl">
                Blog Articles
              </h3>
              <div>
                <Link
                  to={"/events"}
                  className="text-white rounded-full px-2 md:px-8 py-2 bg-[#1A2A75] flex whitespace-nowrap"
                >
                  <span className="hidden md:block">See more</span>
                  <ArrowForward />
                </Link>
              </div>
            </div>
            <p>See what we are talking about. Get engaged.</p>
          </div>
        </div>
        <div className="grid md:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-5 gap-8">
          {value?.map((post: PostInterface) => (
            <BlogCard props={post} />
          ))}
          {/* <div className="flex flex-col gap-y-4">
            <img
              src={stickimg}
              alt="img"
              className="w-full aspect-video object-cover"
            />

            <div>
              <h4 className="font-semibold text-xl">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
              </h4>
            </div>
            <div className="">
              <p className="text-slate-500">November 4th 2023</p>
            </div>
          </div>
          <div className="flex flex-col gap-y-4">
            <img
              src={stickimg}
              alt="img"
              className="w-full aspect-video object-cover"
            />

            <div>
              <h4 className="font-semibold text-xl">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
              </h4>
            </div>
            <div className="">
              <p className="text-slate-500">November 4th 2023</p>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
}
