import { ArrowForward } from "@mui/icons-material";
import "pure-react-carousel/dist/react-carousel.es.css";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { Link } from "react-router-dom";

const AboutSection = () => {
  


  
  return (
    <section className="pt-20 md:py-20 px-8 md:px-28 pb-8 border-b">
      <div className="max-w-screen-2xl mx-auto grid content-center text-center">
        <div className="xl:col-span-2 space-y-4">
          <h2 className="text-5xl md:text-7xl font-bold">About</h2>
          <h3 className="text-3xl md:text-5xl font-semibold">
            Intangible Cultural Heritage
          </h3>
          <p className=" py-4">
            Intangible cultural heritage refers to practices, representations,
            expressions, knowledge, and know-how that are practiced by
            communities, groups, and /or individuals. These practices are
            recognized as part of the cultural heritage, identity, and
            continuity of concerned communities, and are transmitted from one
            generation to the next. This heritage includes, but is not limited
            to, oral traditions; performing arts; social practices, rituals, and
            festive events; knowledge and practices concerning the nature and
            the universe; and traditional craft.{" "}
          </p>
          <Link
            to={"/aboutus"}
            className=" text-white rounded-full px-8 py-2 bg-[#1A2A75]"
          >
            Learn more <ArrowForward />
          </Link>
         </div>
        {/*
        <div className="xl:col-span-3 w-full xl:max-w-2xl xl:ml-auto grid md:grid-cols-2 gap-12">
          <Link
            to={""}
            style={{ backgroundImage: `url(${stickimg})` }}
            className="bg-slate-200 bg-cover aspect-square rounded-md hover:shadow-xl shadow-slate-600 overflow-hidden"
          >
            <div className="bg-slate-950/50 relative w-full h-full">
              <h3 className="text-white font-semibold text-2xl absolute bottom-5 left-5">
                Title
              </h3>
            </div>
          </Link>
          <Link
            to={""}
            style={{ backgroundImage: `url(${stickimg})` }}
            className="bg-slate-200 bg-cover aspect-square rounded-md hover:shadow-xl shadow-slate-600 overflow-hidden"
          >
            <div className="bg-slate-950/50 relative w-full h-full">
              <h3 className="text-white font-semibold text-2xl absolute bottom-5 left-5">
                Title
              </h3>
            </div>
          </Link>
          <Link
            to={""}
            style={{ backgroundImage: `url(${stickimg})` }}
            className="bg-slate-200 bg-cover aspect-square rounded-md hover:shadow-xl shadow-slate-600 overflow-hidden"
          >
            <div className="bg-slate-950/50 relative w-full h-full">
              <h3 className="text-white font-semibold text-2xl absolute bottom-5 left-5">
                Title
              </h3>
            </div>
          </Link>
          <Link
            to={""}
            style={{ backgroundImage: `url(${stickimg})` }}
            className="bg-slate-200 bg-cover aspect-square rounded-md hover:shadow-xl shadow-slate-600 overflow-hidden"
          >
            <div className="bg-slate-950/50 relative w-full h-full">
              <h3 className="text-white font-semibold text-2xl absolute bottom-5 left-5">
                Title
              </h3>
            </div>
          </Link>
        </div>
        */}
        {/* <Carousel /> */}
      </div>
    </section>
  );
};



export default AboutSection;
