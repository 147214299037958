import { collection, query } from "firebase/firestore";
import { FunctionComponent } from "react";
import { useCollectionDataOnce } from "react-firebase-hooks/firestore";
import { Routes, useParams } from "react-router";
import BlogCard from "../../components/categories/blogcard";
import { postConverter } from "../../interfaces/post";
import { db } from "../../services/firebaseconfig";
import Layout from "../layout";
import paradeImg from "../../FirstSection/parade.jpg";

const Category: FunctionComponent = () => {
  // const router: NextRouter = useRouter();
  const { id, title } = useParams();
  console.log(Routes);

  const [value, loading, error] = useCollectionDataOnce(
    query(collection(db, "posts")).withConverter(postConverter),
    {
      getOptions: { source: "server" },
    }
  );

  return (
    <>
      <Layout>
        <main>
          <section className=" flex flex-col justify-start items-center ">
          <div className=" h-[40vh] bg-black relative overflow-hidden w-full">
              <img
                className="w-full h-full object-cover object-[50%_20%]"
                alt="Parade"
                src={paradeImg}
              />

              <div
                className={` absolute top-0 bg-black/50 text-white flex flex-col justify-center items-center h-full  w-full 
               `}
              >
                <div className=" w-fit">
                  <h1 className="  text-5xl font-bold">{title}</h1>
                </div>
              </div>
            </div>
            
            <div className=" grid grid-cols-1 lg:grid-cols-4 gap-4 py-4 min-h-screen w-screen px-4">
              {value?.map((doc) => (
                <BlogCard key={doc.id} props={doc} />
              ))}
            </div>
          </section>
        </main>
      </Layout>
    </>
  );
};

export default Category;
