import { FunctionComponent, useEffect } from "react";
import { useLocation } from "react-router-dom";

import React from 'react';
 
type Props = {
    children: string | JSX.Element | JSX.Element[] 
  }
export  const  ScrollToTop: FunctionComponent<Props> = ({children}:Props)=> {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return <div>
    {children}
  </div>;
}