import { Link } from "react-router-dom";
import { PostInterface } from "../../interfaces/post";
import para from "../../resources/FirstSection/parade.jpg";
const BlogCard = ({ props }: { props: PostInterface }) => {
  return (
    <Link to={`../post/${props.id}`}>
      <div className="rounded-xl overflow-hidden border hover:shadow-lg transition duration-300">
        <img
          src={props.image ?? para}
          alt="img"
          className="w-full aspect-video object-cover"
        />
        <div className="p-4">
        <div>
          <h4 className="font-semibold text-lg">{props.title ?? "Title"}</h4>
        </div>
        <div className="">
          <p className="text-slate-500">
            {props.postDate.toDate().toLocaleString(undefined, {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </p>
        </div>
        </div>
      </div>

      {/* <div className=" my-2 overflow-hidden group flex flex-col md:flex-row justify-center items-start text-black bg-white w-full  md:h-48  rounded-xl shadow-sm hover:shadow-lg   transition-all duration-300 ease-in ">
        <div className=" bg-black w-full h-60 md:w-4/12 md:h-full relative ">
          <img
            className=" absolute  w-full  h-full opacity-70  object-cover"
            alt="Parade"
            src={props.image ?? para}
          ></img>
        </div>
        <div className="flex flex-col md:w-8/12 w-full h-full p-4">
          <h6 className=" font-bold text-2xl text-ellipsis transition-all duration-100 ease-out flex-[1] ">
            {props.title ?? "Title"}
          </h6>
          <p className=" text-sm w-full line-clamp-3 flex-[2]">
            {props.description ??
              "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Esse fugit reprehenderit accusamus quos recusandae distinctio temporibus expedita dolores nihil maiores debitis explicabo est, reiciendis corrupti molestias sit ex consectetur. Sequi."}
          </p>
          <div className=" flex flex-row py-4 flex-[1]">
            <div className="bg-transparent text-black border-black  border-2 rounded-md text-center text-sm font-medium w-min px-4 py-1 mx-2">
              tag
            </div>
            <div className="bg-transparent text-black border-black  border-2 rounded-md text-center text-sm font-medium w-min px-4 py-1 mx-2">
              tag
            </div>
            <div className="bg-transparent text-black border-black  border-2 rounded-md text-center text-sm font-medium w-min px-4 py-1 mx-2">
              tag
            </div>
          </div>
        </div>
      </div> */}
    </Link>
  );
};

export default BlogCard;
