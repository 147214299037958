import { ArrowForward } from "@mui/icons-material";
import { collection } from "firebase/firestore";
import { FunctionComponent } from "react";
import { useCollectionOnce } from "react-firebase-hooks/firestore";
import { Link } from "react-router-dom";
import { eventConverter } from "../../interfaces/events";
import { db } from "../../services/firebaseconfig";
import EventCard from "../events/eventcard";

const WhatsNewSection: FunctionComponent = () => {
  const [value, loading, error] = useCollectionOnce(
    collection(db, "events").withConverter(eventConverter),
    {
      getOptions: { source: "server" },
    }
  );
  return (
    <section className="pt-20 px-8 md:px-28 border-b pb-10">
      <div className="max-w-screen-2xl mx-auto flex flex-col gap-y-12">
        <div className="flex justify-between">
          <div className="flex flex-col gap-y-4 w-full">
            <div className="flex justify-between">
              <h3 className="font-semibold text-3xl md:text-4xl">
                Upcoming Events
              </h3>
              <div>
                <Link
                  to={"/events"}
                  className="text-white rounded-full px-2 md:px-8 py-2 bg-[#1A2A75] flex whitespace-nowrap"
                >
                  <span className="hidden md:block">See more</span>{" "}
                  <ArrowForward />
                </Link>
              </div>
            </div>
            <p>
              View our upcoming events and indulge yourself in culture and
              heritage.
            </p>
          </div>
        </div>
        <div className="grid md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-8">
          {value?.docs.map((doc) => (
            <EventCard
              key={doc.id}
              id={doc.id}
              tags={doc.data().tags}
              title={doc?.data().title ?? "Title"}
              description={doc?.data().description}
              eventDate={doc?.data().eventDate}
              postDate={doc?.data().postDate}
              image={doc?.data().image ?? ""}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhatsNewSection;
