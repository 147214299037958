import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import ContactForm from "../components/contact/contact-form";
import InventoryForm from "../components/contact/inventory-form";
import Layout from "./layout";

const Contact = () => {
  return (
    <>
      <Layout>
        <section className=" min-h-screen px-4 flex flex-col items-center text-center pt-32 pb-16">
          <h4 className=" font-bold text-3xl">Contact Us</h4>
          <h6 className=" font-semibold text-md  text-zinc-500 py-5">
            Any questions or remarks? Just write a message.
          </h6>

            <ContactForm />

  
        </section>
      </Layout>
    </>
  );
};

export async function getStaticProps(context: any) {
  return {
    props: {}, // will be passed to the page component as props
  };
}

export default Contact;
