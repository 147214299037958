import { TiSpiral } from "react-icons/ti";
import img from "../../FirstSection/parade.jpg";
export default function Purpose() {
  return (
    <section className="w-screen mx-auto p-4 flex flex-col space-y-8">
      <div className="grid lg:grid-cols-2 h-full justify-center items-center ">
        <div className="p-8 flex flex-col gap-8 items-center text-center">
          <h1 className="text-5xl font-bold">About this Website</h1>
          <p className="text-xl font-light">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quia, id minus? Dolores, ipsam quos molestiae, iste voluptatem odio neque, maxime minus et quod at nobis? Recusandae explicabo similique quisquam dolores?
          </p>
          
        </div>
        <div className="  flex justify-center">
          <img
            src={img}
            alt=""
            className="max-w-[500px] max-h-[600px] w-full h-full object-cover shadow-xl rounded-lg"
          />
        </div>
      </div>
      
      <div className="grid lg:grid-cols-2 h-full justify-center items-center center ">
        <div className="p-8 flex flex-col gap-8 items-center text-center order-1 lg:order-2    ">
          <h1 className="text-5xl font-bold">About our Inventory</h1>
          <p className="text-xl font-light">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quia, id minus? Dolores, ipsam quos molestiae, iste voluptatem odio neque, maxime minus et quod at nobis? Recusandae explicabo similique quisquam dolores?
          </p>
          
        </div>
        <div className=" order-2 lg:order-1 flex justify-center" >
          <img
            src={img}
            alt=""
            className=" max-w-[500px] max-h-[600px] w-full h-full object-cover shadow-xl rounded-lg"
          />
        </div>
      </div>
      <div className="grid lg:grid-cols-2 h-full justify-center items-center ">
        <div className="p-8 flex flex-col gap-8 items-center text-center">
          <h1 className="text-5xl font-bold">About the 2003 Convention</h1>
          <p className="text-xl font-light">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quia, id minus? Dolores, ipsam quos molestiae, iste voluptatem odio neque, maxime minus et quod at nobis? Recusandae explicabo similique quisquam dolores?
          </p>
          
        </div>
        <div className="  flex justify-center">
          <img
            src={img}
            alt=""
            className=" max-w-[500px] max-h-[600px] w-full h-full object-cover shadow-xl rounded-lg"
          />
        </div>
      </div>
    </section>
  );
}
