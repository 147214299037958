import { Link } from "react-router-dom";
import { EventsInterface } from "../../interfaces/events";
import para from "../../resources/FirstSection/parade.jpg";

const EventCard = (props: EventsInterface) => {
  return (
    <Link to={`/events/${props.id}`}>
      <div className=" rounded-xl overflow-hidden border hover:shadow-lg transition duration-300">
        <div className=" relative">
          <img
            className="w-full aspect-video md:aspect-square object-cover"
            alt="Parade"
            src={props.image ?? para}
          />

          <div className="uppercase absolute top-0 left-0 text-white font-bold bg-[#1A2A75]/50 p-2 rounded-br-xl">
            <p className="text-3xl">
              {new Intl.DateTimeFormat("en-US", { month: "short" }).format(
                new Date(props.eventDate?.toDate())
              )}
            </p>
            <p className="text-5xl">
              {props.eventDate?.toDate().getDate() ?? 18}
            </p>
          </div>
          <div className="absolute bottom-0 w-full bg-slate-950/50 p-4">
            <h4 className="text-white font-semibold text-xl line-clamp-1 truncate">
              {props.title ?? "Title"}
            </h4>
          </div>
        </div>
        <div className="p-4">
          <div className=" flex flex-col overflow-hidden">
            <p className=" line-clamp-3  ">{props.description ?? "No Data"}</p>
          </div>

          <div className=" flex flex-row flex-wrap py-2 ">
            {props.tags?.map((data) => (
              <div
                key={data}
                className="text-center text-sm font-medium py-1 mr-2 text-sky-500"
              >
                #{data}
              </div>
            ))}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default EventCard;
