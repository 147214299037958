import { collection } from "firebase/firestore";
import { FunctionComponent } from "react";
import { useCollectionOnce } from "react-firebase-hooks/firestore";
import { AiOutlineSearch } from "react-icons/ai";
import paradeImg from "../../FirstSection/parade.jpg";
import CategoryCard from "../../components/categories/categorycard";
import ErrorPage from "../../components/error";
import Loading from "../../components/loading";
import { categoryConverter } from "../../interfaces/category";
import { db } from "../../services/firebaseconfig";
import Layout from "../layout";

const Categories: FunctionComponent = () => {
  const [value, loading, error] = useCollectionOnce(
    collection(db, "categories").withConverter(categoryConverter),
    {
      getOptions: { source: "server" },
    }
  );
  value?.docs.sort((a,b)=>a.data().title?.localeCompare(b.data().title ?? "") ?? 0)
  return (
    <>
      <Layout>
        {error ? (
          <ErrorPage></ErrorPage>
        ) : loading ? (
          <Loading></Loading>
        ) : (
          <>
            <div className=" h-[40vh] bg-black relative overflow-hidden w-full">
              <img
                className="w-full h-full object-cover object-[50%_20%]"
                alt="Parade"
                src={paradeImg}
              />

              <div
                className={` absolute top-0 bg-black/50 text-white flex flex-col justify-center items-center h-full  w-full 
               `}
              >
                <div className=" w-fit">
                  <h1 className="  text-5xl font-bold">ICH Elements</h1>
                </div>
              </div>
            </div>
            <section className="max-w-screen-2xl mx-auto p-4 space-y-4">
              <form
                className=" flex items-center w-full xl:w-1/3 relative "
                action=""
              >
                <AiOutlineSearch className="w-6 h-6 absolute left-2" />
                <input
                  className="py-2  bg-slate-100  w-full  border-royalblue placeholder:font-medium pl-10 rounded-full focus:outline-none"
                  type="text"
                  placeholder="Search"
                  id="search"
                />
              </form>

              <div className=" grid grid-cols-1 md:grid-cols-2  lg:grid-cols-5 gap-4 w-full min-h-screen">
                {value?.docs.map((doc) => (
                  <CategoryCard
                    key={doc.id}
                    id={doc.id}
                    title={doc?.data().title ?? "Title"}
                    image={doc?.data().image}
                  ></CategoryCard>
                ))}
              </div>
            </section>
          </>
        )}
      </Layout>
    </>
  );
};
export async function getStaticProps(context: any) {
  return {
    props: {}, // will be passed to the page component as props
  };
}

export default Categories;
